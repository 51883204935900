<template>
  <div class="page">
   
      
        <div class="content">
          <keep-alive>
            <component :is="personalCenterComponents"></component>
          </keep-alive>
        </div>
    
  </div>
</template>

<script>
import { mapState } from "vuex";

// 批量引入components/setting目录下的所有组件
const components = require
  .context("@components/setting/", false, /.*\.vue/)
  .keys()
  .reduce((components, componentsPath) => {
    const componentsName = componentsPath.replace(/\.\/(.*)\.vue/, "$1");
    components[componentsName] =
      require(`@components/setting/${componentsName}`).default;
    return components;
  }, {});

export default {
  components,
  data() {
    return {
      navList: [
      
        { label: "隐私服务", components: "privacyService" },
       
      ],
      ComponentsIndex: "0",
    };
  },
  watch: {
    $route(val) {
      let index = val.query.index;

      if (index) {
        this.ComponentsIndex = index;
      }
    },
    ComponentsIndex(val) {
      // this.$route.query.index = val;
      const newUrl = `#/setting?index=${val}`;
      window.history.replaceState("", "", newUrl);
    },
  },
  created() {
    let index = this.$route.query.index;
    if (index) {
      this.ComponentsIndex = index;
    }
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
    personalCenterComponents() {
      return this.navList[this.ComponentsIndex.split("-")[0]].childList
        ? this.navList[this.ComponentsIndex.split("-")[0]].childList[
            this.ComponentsIndex.split("-")[1]
          ].components
        : this.navList[this.ComponentsIndex.split("-")[0]].components;
    },
  },
  methods: {
    seleasd(e) {
      window.scrollTo(0, 0);
      if (e == 0)
        if (localStorage.token) {
          this.ComponentsIndex = e;
        } else this.xin.message("请先登录", "err");
      else this.ComponentsIndex = e;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  background-color: #f2f2f2;
  > section {
    > section {
      margin-top: 30px;
      display: flex;
      > nav {
        width: 196px;
        height: max-content;
        background-color: white;
        position: sticky;
        top: 100px;
        ::v-deep {
          .el-menu {
            .el-menu--inline {
              overflow: hidden;
              .el-menu-item {
                padding-left: 50px !important;
              }
            }
            .el-menu-item,
            .el-submenu {
              span {
                margin-left: 10px;
              }
            }
          }
          .is-active {
            &::before {
              position: absolute;
              left: 0;
              top: calc((100% - 35px) / 2);
              content: "";
              display: block;
              width: 5px;
              height: 35px;
              background-color: $--themeColor;
            }
          }
        }
      }
      > .content {
        flex: 1;
        margin-left: 20px;
        // background-color: white;
        height: max-content;
      }
    }
  }
}
.header-box{
	display: none;
}
// .privacyService{
// 	width:1200px;
// }
</style>
